import H5 from '@material-tailwind/react/Heading5';
import LeadText from '@material-tailwind/react/LeadText';
import Icon from '@material-tailwind/react/Icon';

export default function DefaultFooter() {
    return (
        <>
            <footer className="relative bg-gray-100 pt-8 pb-6">
                <div className="container max-w-7xl mx-auto px-4">
                    <div className="flex flex-wrap text-center lg:text-left pt-6">
                        <div className="w-full lg:w-6/12 px-4">
                            <H5 color="gray">TalentTide®</H5>
                            <div className="-mt-4">
                                <LeadText color="blueGray">
                                    Using AI to Engage, Hire and Assess Great Talent like never before.
                                </LeadText>
                            </div>
                        </div>

                    </div>
                    <hr className="my-6 border-gray-300" />
                    <div className="flex flex-wrap items-center md:justify-between justify-center">
                        <div className="w-full md:w-4/12 px-4 mx-auto text-center">
                            <div className="text-sm text-gray-700 font-medium py-1">
                                © 2012 - {new Date().getFullYear()} <a href="https://www.me-talent.com" target="_blank">ME Talent Solutions</a>.  All Rights Reserved.
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

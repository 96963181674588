

import Card from '@material-tailwind/react/Card';
import CardImage from '@material-tailwind/react/CardImage';
import CardBody from '@material-tailwind/react/CardBody';
import Icon from '@material-tailwind/react/Icon';
import H4 from '@material-tailwind/react/Heading4';
import H6 from '@material-tailwind/react/Heading6';
import LeadText from '@material-tailwind/react/LeadText';
import Paragraph from '@material-tailwind/react/Paragraph';
import StatusCard from 'components/landing/StatusCard';
import TalentAssessment from 'assets/img/tt_ea.jpg';
import BusinessAssessment from 'assets/img/tt_bat.jpg';

export default function WorkingSection() {
    return (
        <section className="pb-20 bg-gray-100 -mt-32">
            <div className="container max-w-7xl mx-auto px-4">
                <div className="flex flex-wrap relative z-50">
                    <StatusCard color="red" icon="emoji_people" title="Hire for Potential, Not Experience">
                    <li>	Hire your next generation of qualified leaders who fit your organization’s culture and complement your existing global teams. </li>
                    &nbsp;
                    <li>	Unlock human potential, promote inclusivity of underrepresented groups and become more efficient and effective in your hiring to drive breakthrough business performance. </li>
                    &nbsp;
                    <li>“Tide” maps uncover trends on how your talent is changing over time, and can identify upcoming talent gaps to enable more proactive recruitment. </li>
                    </StatusCard>
                    <StatusCard
                        color="lightBlue"
                        icon="manage_accounts"
                        title="Cultivate your workforce of the future"
                    >
                        <li> TalentTide utilizes natural language processing for CV parsing, extracting key data during interviews such as through our Microsoft Teams integration, bespoke talent assessments, online applications and from other data sources. Data that is often overlooked yet so valuable for businesses to capture for future decision making.</li>
                        &nbsp;
                        <li> Proactively predicting changes in your organization such as upcoming talent and market changes. </li>

                    </StatusCard>
                    <StatusCard
                        color="teal"
                        icon="flight_takeoff"
                        title="Connect Talent and Opportunity"
                    >
                    <li>TalentTide provides a revolutionary HR platform with a brand new AI interface, capable of conducting semantic search and providing answers to your important leadership questions.</li>
                    &nbsp;
                    <li>The future of possibility awaits as we link recruitment with education through data to match individuals with their optimum career paths.</li>

                    </StatusCard>
                </div>

                <div className="flex flex-wrap items-center mt-32">


                    <div className="w-full md:w-4/12 px-4 mx-auto flex justify-center mt-24 lg:mt-0">
                        <Card>
                            <CardImage alt="Card Image" src={TalentAssessment} />
                            <CardBody>
                                <H6 color="gray">TalentTide®<br />Executive Assessment</H6>
                                <Paragraph color="blueGray">
                                    Our proprietary Executive Assessment continuously adapts to market trends, utilizes predictive data analytics, and has been developed in line with expected behaviors and soft-skills, required both on a role and company-wide level.
                                </Paragraph>
                            </CardBody>
                        </Card>
                    </div>

                    <div className="w-full md:w-4/12 px-4 mx-auto flex justify-center mt-24 lg:mt-0">
                        <Card>
                            <CardImage alt="Card Image" src={BusinessAssessment} />
                            <CardBody>
                                <H6 color="gray">TalentTide®<br />Business Assessment Tool</H6>
                                <Paragraph color="blueGray">
                                    Our bespoke Business Assessment Tools assist in unlocking your organization's full potential.
                                </Paragraph>
                            </CardBody>
                        </Card>
                    </div>


                </div>
            </div>
        </section>
    );
}

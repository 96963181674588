import DefaultNavbar from 'components/DefaultNavbar';
import DefaultFooter from 'components/DefaultFooter';
import Header from 'components/landing/Header';
import WorkingSection from 'components/landing/WorkingSection';
import { Helmet } from 'react-helmet';

export default function Landing() {
    return (
        <>

        <Helmet>
        <title>TalentTide® - Make Data Driven Decisions</title>
        <meta name="description" content="TalentTide® Using AI to Engage, Hire and Assess Great Talent like never before to help you understand the value of the people in your business." />
        <meta name="keywords" content="Executive Search Software, Executive Assessment, CV Parsing, Artificial Intelligence, Business Intelligence, Business Diagnostics, Machine Learning, Software, Human Resources, HR Technology, AI, Recruitment, Talent Management, Enterprise Cloud Applications, Human Capital Management, Analytics, Succession Planning" />
        </Helmet>

            <div className="absolute w-full z-20">
                <DefaultNavbar />
            </div>
            <main>
                <Header />
                <WorkingSection />
            </main>
            <DefaultFooter />
        </>
    );
}

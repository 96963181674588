export default function SimpleFooter() {
    return (
        <>
            <footer className="pt-8 pb-6">
                <div className="container max-w-7xl mx-auto px-4">
                    <div className="flex flex-wrap justify-center gap-4 md:justify-between">
                        <ul className="list-unstyled flex gap-8">
                            <li>
                                <a
                                    href="mailto:support@talent-tide.com"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-white font-medium block pb-2 text-sm"
                                >
                                    Technical Support
                                </a>
                            </li>
                        </ul>

                        <div className="text-sm text-white font-medium">
                            © 2012 - {new Date().getFullYear()} {' '}
                            <a
                                href="https://www.me-talent.com"
                                className="text-white"
                            >
                                ME Talent Solutions
                            </a>.  All Rights Reserved.
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
